<template>
  <div class="page-wrap phone-robot">
    <div class="top-bar">
      <mtop />
    </div>
    <div class="banner">
      <div class="inner">
        <div class="left">
          <div class="title">智能质检</div>
          <div class="desc">应用离线语音转写与规则引擎技术，对海量录音文件、会话文本进行自动处理与分析，实现流程质检、合规质检、专项质检等功能，大幅度提高质检效率和抽检覆盖面</div>
          <div class="btn" @click="dialogVisible = true, clickMenu('免费试用')">预约演示</div>
        </div>
        <div class="right bg-right"></div>
      </div>
    </div>

    <!-- 产品功能 -->
    <div class="section bg-white func">
      <div class="title">产品功能</div>
      <div class="content">
        <div class="list">
          <div class="item">
            <div class="text-info" style="width: 466px;">
              <div class="title">全渠道全量质检支持</div>
              <div class="desc">智能质检系统能够支持电话、APP、IM、企微等多种渠道的文本和语音数据质检，实现跨渠道会话的关联质检和全量数据质检，确保质检工作的全面性和一致性</div>
            </div>
            <img src="@/assets/quality-check/fun-1.png" style="width: 670px;height: 474px" />
          </div>
          <div class="item">
            <img src="@/assets/quality-check/fun-2.png" style="width: 600px;height: 514px" />
            <div class="text-info" style="width: 485px;">
              <div class="title">流程质检</div>
              <div class="desc">可实现按流程节点顺序进行评分，如未命中中间任一节点则流程结束，并提交流程质检分数，同时可以针对售前、售中、售后各业务实际标准流程规则进行质检，确保服务流程的规范性和高效性</div>
            </div>
          </div>
          <div class="item">
            <div class="text-info" style="width: 428px;">
              <div class="title">质检方案配置</div>
              <div class="desc">系统支持根据方案的数据筛选条件、规则、维度进行全量质检，同时针对不同的质检维度可单独设计评分上限，以更合理更灵活的方式进行质检方案评分。</div>
            </div>
            <img src="@/assets/quality-check/fun-3.png" style="width: 600px;height: 514px" />
          </div>
          <div class="item">
            <img src="@/assets/quality-check/fun-4.png" style="width: 600px;height: 463px" />
            <div class="text-info" style="width: 485px;">
              <div class="title">可视化质检统计</div>
              <div class="desc">可统计需待办的内容和系统任务数据等，支持查看系统概览数据、规则命中情况和标签词云统计，分析坐席服务不合规和需加强改善的用语，提高用户满意度。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="section advantage">
      <div class="title">产品优势</div>
      <div class="content">
        <div class="list">
          <div class="item" v-for="(item, index) in advantageList" :key="index">
            <div class="icon">
              <img :src="getIcon(item)" alt="" />
            </div>
            <div class="info">
              <div class="title">{{item.name}}</div>
              <div class="desc">{{item.content}}</div>
            </div>
          </div>
        </div>
        <div class="bg-adv"></div>
      </div>
    </div>

    <!-- 应用场景 -->
    <div class="section bg-white scene">
      <div class="title">应用场景</div>
      <div class="content">
        <div class="list">
          <div class="item" :class="{'border-top': index >=3 && index <= 5, 'border-r': index != 2 && index != 5}" v-for="(item, index) in sceneList" :key="index">
            <div class="icon">
              <img :src="getIcon(item)" alt="" />
            </div>
            <div class="info">
              <div class="title">{{item.name}}</div>
              <div class="desc">{{item.content}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 客户案例 -->
    <div class="section case">
      <div class="title">客户案例</div>
      <div class="content">
        <div class="detail-panel">
          <div class="left">
            <div class="bg-left"></div>
          </div>
          <div class="right">
            <div class="title">汽车行业：某车企离线质检分析系统</div>
            <div class="info-panel">
              <div class="item item-1">
                <div class="top">
                  <img class="icon" src="@/assets/quality-check/icon-case.png"/>
                  <span class="title">建设内容</span>
                </div>
                <div class="desc">建设线索外呼平台离线质检分析系统。采用离线质检的方式，获取质检语音音频及文本实施质检，并定期将质检结果数据导入公司业务 系统加以运用。</div>
                <div class="bottom">
                  <div class="btn btn-blue">服务质量提升</div>
                  <div class="btn btn-orange">绩效考核评估</div>
                </div>
              </div>
              <div class="item item-2">
                <div class="top">
                  <div class="bg-item bg-l">
                   <div class="info">
                     <div class="count">88%</div>
                     <div class="text">转写准确率</div>
                   </div>
                  </div>
                  <div class="bg-item bg-r">
                    <div class="info">
                      <div class="count">94%</div>
                      <div class="text">质检准确率</div>
                    </div>
                  </div>
                </div>
                <div class="info-list">
                  <div class="item">
                    <div class="top">100%</div>
                    <div class="bottom">质检覆盖率%</div>
                  </div>
                  <div class="item">
                    <div class="top">100%</div>
                    <div class="bottom">工作效率提升%</div>
                  </div>
                  <div class="item">
                    <div class="top">0.3%</div>
                    <div class="bottom">申诉率降低</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <partner/>

    <fotterBanner></fotterBanner>
    <mbottom />

    <demonstrate
        :dialogVisible="dialogVisible"
        @closeDialog="dialogVisible = false"
    ></demonstrate>
  </div>
</template>

<script>
import mtop from "@/components/top.vue";
import Partner from "@/components/partner";
import mbottom from "@/components/bottom.vue";
import fotterBanner from "@/components/fotterBanner.vue";
import demonstrate from "@/components/demonstrate.vue";
import {staClickMenu} from "@/utils";
export default {
  components: {
    Partner,
    mtop,
    mbottom,
    fotterBanner,
    demonstrate
  },
  data() {
    return {
      dialogVisible: false,
      advantageList: [
        {
          name: '精准语义识别',
          content: '支持根据会话内容精准识别用户意图，并结合规则引擎语义算子进行规则匹配，优化规则质检的准确度。',
          icon: 'icon-adv-1.png'
        },
        {
          name: '质检规则定制',
          content: '根据行业业务场景需求及客户实际场景，可实现流程质检、合规质检类型的规则定制化，更符合客户实际应用场景。',
          icon: 'icon-adv-2.png'
        },
        {
          name: '行业质检方案',
          content: '根据多年各行各业质检交付项目经验，系统可提供内置的行业质检方案，协助客户制定标准化质检方案。',
          icon: 'icon-adv-3.png'
        },
        {
          name: '规则验证调优',
          content: '支持规则配置后进行验证调优，确保质检规则上线后可满足客户实际应用场景需求。',
          icon: 'icon-adv-4.png'
        }
      ],
      sceneList: [
        {
          name: '贷款催收类',
          content: '各类货款催收业务的电话录音质检，如信用卡还款催收、经营贷/信用贷还款催收、消费金融还款催收等投诉风险管控；',
          icon: 'icon-scene-1.png'
        },
        {
          name: '电话营销类',
          content: '客户关系维护和营销电话质检，如客户线索和商机营销电话的话术和流程质检，客户投诉分析和客户购买意向自动登记等；',
          icon: 'icon-scene-2.png'
        },
        {
          name: '在线业务办理',
          content: '如在线保险报案受理、事件结案回访、在线服务、订单办理等业务场景，进行话术质检、流程质检、客户信息自动收集记录和信息校验；',
          icon: 'icon-scene-3.png'
        },
        {
          name: '事件回访类',
          content: '如事件结案回访、客户服务回访等场景进行质检；',
          icon: 'icon-scene-4.png'
        },
        {
          name: '客户服务类',
          content: '人工客户服务电话质检和人工在线文本客服质检，提高客户服务态度和服务标准，自动收集客户反馈建议等；',
          icon: 'icon-scene-5.png'
        },
        {
          name: '线路外呼质检',
          content: '对线路上进行的语音话务是否符合规范进行检测，主要用于电诈识别、用语规范、业务匹配，可以有效降低业务风险',
          icon: 'icon-scene-6.png'
        }
      ],
      prodValueList: [
        {
          name: '对话管理',
          content: `多轮对话<br/>复杂场景意图识别`,
        },
        {
          name: '数据运营',
          content: `数据可视化<br/>对话检索`,
        },
        {
          name: '语音交互',
          content: `实现多样式<br/>语音交互`,
        }
      ]
    }
  },
  methods: {
    getIcon(item) {
      return require(`@/assets/quality-check/${item.icon}`)
    },
    clickMenu(menuName, url) {
      if (url) {
        this.$router.replace({
          path: url,
        });
      } else {
        if (menuName != '免费试用') {
          this.$toast("敬请期待");
        }
      }
      staClickMenu(menuName)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
